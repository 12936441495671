.custom-toast-position {
    position: fixed;
    top: 5rem; /* Adjust the top position as needed */
    right: 5rem; /* Adjust the right position as needed */
    text-align: center;
    background-color: rgba(86, 249, 86, 0.799);
    font-weight: bold;
    padding: 20px;
    border-radius: 10px; 
    color: #fff;
    

  }
  /* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;800&display=swap'); */
  *{
    margin: 0;
    padding: 0;
  }
  body{
    margin: 0;
    padding: 0;
  
    font-family: 'Noto Sans', sans-serif;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  /* .container{
    width: 100% !important;
    max-width: 100% !important;
    padding-left: 4% !important;
    padding-right: 4% !important;
    max-width: 100% !important;
    
  } */